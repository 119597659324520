@import '../../theme/HSPlusVariables.less';

.company-selector {
    width: 95%;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
}

.company-name {
    margin-left: 8px;
    padding-top: 2px;
    float: left;

    .ant-avatar {
        background-color: @hs-blue-6;
        margin: 5px 5px 5px 0px;
    }

    .ant-avatar {
        &.disabled {
            background-color: @hs-disabled;
        }
    }

    &.single-item {
        padding-right: 40px;
        height: 36px;
        margin-top: 15px;
    }
}

.ant-select-selector {

    .company-name {
        width: 90% !important;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 600;
    }
}

.company-selector {
    &.ant-select > .ant-select-selector {
        height: 35px;
    }

    &:not(.ant-select-open) {
        &.ant-select > .ant-select-selector {
            border: none;
            background: transparent;
        }
    }
}

.ant-select-open {
    color: @hs-blue-10;
}