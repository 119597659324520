.ticket-page {
  .status {
    width: 102px;
    text-align: center;
  }

  .label {
    color: #8C8C8C;
    margin-right: 5px;
    margin-left: 20px;
  }

  .no-padding {
    padding: 0;
  }

  .divider {
    font-size: 12px;
    color: #595959;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .message {
    margin-bottom: 20px;

    .message-container {
      border: 1px solid #F0F0F0;
      border-radius: 0px 24px 24px 24px;
      padding: 24px;
      flex: 1;

      &.mine {
        border-radius: 24px 0px 24px 24px;
      }

      p {
        margin: 0;
      }
    }
  }

  .author {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .avatar {
    font-size: 12px !important;
    color: #FFFFFF;
    background-color: #FF85C0;
    margin-right: 10px;

    &.mine {
      background-color: #B37FEB;
      margin-left: 10px;
    }
  }

  .img-btn {
    margin-top: 15px;
  }

  .rich-editor-wrapper {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}