@import '../../theme/HSPlusVariables.less';

.ant-layout-sider-zero-width-trigger {
  display: block;
}

.sidebar {
  position: fixed !important;

  .ant-drawer-content-wrapper {
    min-width: 250px;
    max-width: 350px;
    height: 90vh;
    margin: 2% 2%;
    border-radius: 12px;      
    position: relative !important;
    border: 1px solid #C1EBF6;
  }

  .ant-drawer-body {
    padding: 12px
  }
}

.ant-layout-sider-zero-width-trigger {
  top: 92vh;
  left: 2%;
  position: fixed;
  background-color: @hs-blue-1 !important;
  border-radius: 12px; 
  z-index: 9999;
  color: @hs-blue-10;
  padding-top: 2px;
}

.ant-menu .ant-menu-item-selected {
  background-color:@hs-blue-2 !important
}

@media (min-width: @hs-screen-xl) {
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  .sidebar {
    .ant-drawer-content-wrapper {
      min-width: 300px;
      max-width: 350px;
      height: 90vh;
      margin: 2% auto;
      border-radius: 12px;      
      position: relative !important;
    }  
  }

  .ant-layout-sider {
    min-width: 300px;
    position: fixed !important;
    z-index: 1;
  }

  .content-container {
    margin-left: 276px;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 92vh;
    left: 2%;
    position: fixed;
    background-color: @hs-blue-1 !important;
    border-radius: 12px; 
    z-index: 9999;
    color: @hs-blue-10
  }
}

.sidebar {
  .ant-drawer-content-wrapper {
   
    .ant-drawer-content {
      background-color: @hs-blue-1;
      border-radius: 12px;
      .ant-menu {
        background-color: @hs-blue-1;
        border: none;
      }
    }
  }
}

.ant-layout-sider {
    background-color: @hs-blue-1;

    .ant-menu {
        background-color: @hs-blue-1;
    }

    .ant-menu-inline {
      border-right: none;
    }

    .ant-menu-sub {
      background-color: @hs-blue-1;
    }

    border-radius: 12px;

    .ant-menu-item-divider {
      margin: 0px 5%;
    }

    .ant-menu .ant-menu-item-selected {
      background-color:@hs-blue-2
    }
  }

.menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-select-arrow {
    color: black;
  }

  .top-menu {
    flex: 1;
  }

  .bottom-menu {
    margin: auto 0;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-bottom: 10px;

    .hsPlusInfoCircle {
      float: right;
      color: #018AAC;
      margin-right: 8px;
    }
  
    .navigation-divider {
      border-color: #C1EBF6;
      margin-left: 24px !important;
      margin-right: 24px !important;
    }
  }
}
