@import '../../theme/HSPlusVariables.less';

.status-label {
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;

    &.success {
      background-color: @hs-green;
    }

    &.warning {
      background-color: @hs-orange;
    }

    &.error {
      background-color: @hs-red;
    }
  }

  .label {
    font-size: 12px;

    &.success {
      color: @hs-green;
    }

    &.warning {
      color: @hs-orange;
    }

    &.error {
      color: @hs-red;
    }
  }
}