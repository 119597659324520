.rich-editor-wrapper {
  margin-bottom: 10px;
  flex: 1;

  .rich-editor-container {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    //resize: both;
    //overflow: auto;

    &.error {
      border: 1px solid #ff4d4f;
    }
    
    .editor-btns-container {
      border-bottom: 1px solid #D9D9D9;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
      align-items: center;

      .separator {
        height: 20px;
        width: 1px;
        background-color: #000000;
        margin-right: 12px;
      }

      .editor-btn {
        padding: 0;
        margin-right: 10px;
      }
    }

    .editor-container {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 300px;
      //min-height: 100px;

      .public-DraftEditor-content {
        max-height: 280px;
        overflow: auto;
      }
    }
  }
}


.upload-container {
  .upload-icon-container {
    padding-left: 10px;
    padding-right: 10px;

    .icon-container {
      border-radius: 100px;
      background-color: #E5F7FB;
      width: 48px;
      height: 48px;

      .icon {
        font-size: 18px;
        line-height: 48px;
        color: #23B7DC;
        vertical-align: middle;
      }
    }
  }
}
.upload-comment {
  font-size: 12px;
  color: #8C8C8C;
}

.submit-btn {
  margin-right: 15px;
  margin-bottom: 5px;
}
