.settings-users-page {
  // margin-top: 5rem;
  .users-table{
    .card-header{
      .btn{
        margin: -5px 0px;
      }
    }
  }
}

.ant-input-prefix {
  display: block;
  margin-right: 5px;
}

.btn-delete-user {
  border: none;
  background: transparent;
}

.ant-page-header {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.ant-select {
  border: 1px solid transparent;
  background: transparent;
}

.ant-select:hover, .ant-select:focus {
  border: 1px solid rgba(0, 171, 214, 1);
  border-radius: 8px
}

.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.ant-table-container::after, .ant-table-container::before {
  box-shadow: none !important;
}

table {
  thead {
    th::before {
      width: 0px !important;
      height: 0px !important;
    }

    th:first-child {
      min-width: 230px;
    }
    
    th:nth-child(2) {
      min-width: 300px;
    }

    th:nth-child(3), th:nth-child(4) {
      min-width: 247px;
    }

    th:last-child {
      width: 56px;
    }
  }

  th {
    min-width: 0 !important;
  }
}
