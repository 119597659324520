.ant-table-wrapper { 
    .ant-form-item {
        margin-bottom: 0px;
    }
}

.ant-table-column-sorters {
    justify-content: flex-start;

    .ant-table-column-sorter {
        margin-left: 10px;
    }
}

.ant-table-column-title {
    flex: none
}