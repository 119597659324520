.user-invitation-container {
    margin-top: 50px;

    .card {
        text-align: center;

        .buttons-row {
            margin-top: 30px;

            button {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}