@import '../../theme/HSPlusVariables.less';

.register-page{
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @hs-blue-1;

  .form-container{
    box-shadow: 0px 0px 10px 0px rgba(51,51,51,0.1);
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    // margin-top: 5%;//calc(-1*(2em + 65px));
    min-width: 400px;
    max-width: 400px;
    .btn{
      margin-top: 30px;
    }

    .ant-form-item-required {
      padding-left: 10px;
    }
    .ant-form-item-required::after {
      color: #ff4d4f !important;
      position: absolute;
      left: -2px;
      top: 4px;
    }
  }
  .logo{
    margin-bottom: 20px;
  }
}
