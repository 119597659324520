@import '../../theme/HSPlusVariables.less';

.ant-page-header-back {
  margin-right: 8px;  
  .anticon
  {
    border: 1px solid @hs-disabled;
    border-radius: 15px;
    padding: 6px;
    
  }

  .ant-page-header-back-button:hover{
    color: @hs-blue-9
  }
}