.content-container {
    &.ant-layout {
        padding: 0px 2%;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .ant-layout-content {
        padding: 24px;
        margin: 0px; 
        min-height: 280px; 
        background-color: #fff; 
        max-width: 1240px;
    }
}