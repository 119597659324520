.type-switch {
  margin-bottom: 15px;
  color: #262626;

  .label {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.tickets-table {
  th {
    min-width: 0 !important;
  }

  .subject-cell {
    p {
      margin: 0;

      .ticket-number {
        color: #8C8C8C;
        font-size: 12px;
        line-height: 20px;
      }

      .title {
        padding: 0;
        line-height: 22px;
        height: 26px;
      }
    }
  }
}

.ticket-state {
  width: 102px;
  text-align: center;
}

.step {
  color: #8C8C8C;
}

.upload-container {
  .upload-icon-container {
    padding-left: 10px;
    padding-right: 10px;

    .icon-container {
      border-radius: 100px;
      background-color: #E5F7FB;
      width: 48px;
      height: 48px;

      .icon {
        font-size: 18px;
        line-height: 48px;
        color: #23B7DC;
        vertical-align: middle;
      }
    }
  }
}
.upload-comment {
  font-size: 12px;
  color: #8C8C8C;
}
