//This file contains global overrides to standard Ant Design Components

@import "./HSPlusVariables.less";

// Overrides for Ant Card Component: https://ant.design/components/card/

Table {
  border : 1px solid #F0F0F0;
  border-radius: 8px;
}

body {
  color: @hs-blue-9;
  background-color: white;
}
.ant-spin-spinning{
  position: absolute;
  z-index: 1010;
  padding-top: 200px;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(255,255,255, 0.8);
}
.ant-layout {
  background-color: #FFFFFF;
}

.ant-card {
  box-shadow: @hs-shadow-2;
}

.ant-card-bordered {
  border: none;
}

// Overrides for Ant Modal Component: https://ant.design/components/modal/

.ant-modal-content {
  border-radius: @hs-border-radius-lg;
}

.ant-modal-header {
  border-top-left-radius: @hs-border-radius-lg;
  border-top-right-radius: @hs-border-radius-lg;
}

.ant-page-header {
  padding-left: 0%;
  .ant-page-header-heading-left {
    // flex-direction: column;
    // align-items: flex-start;
  }
}

.ant-checkbox-inner {
  border-radius: 2px !important;
}

.ant-menu-submenu-selected, .ant-menu-item-selected {
  color: @hs-blue-9;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
    margin-right: 4px;
    color: @hs-gray;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
  content: '' !important;
}

.ant-form-item {
  .ant-select-arrow {
    color: #262626 !important
  }
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-3 {
  margin-top: 24px;
}

.ant-spin-spinning {
  position: fixed;
  top: 0;
}