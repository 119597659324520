.my-profile {
    .ant-card {
        box-shadow: none;

        .ant-card-head {
            border:none;
        }
    }

    .sub-header {
        padding-top:16px;
        padding-bottom: 24px;
    }
}