@import '../../../theme/HSPlusVariables.less';

.project-card {
  cursor: pointer;

  .divider {
    margin: 15px 0 12px 0;
  }

  .support {
    color: @hs-disabled;
    font-size: 12px;
  }

  .link {
    padding: 0;
    height: 25px;
  }
}

.server-group-card {
  cursor: pointer;

  .data-column {

    &.border-right {
      @media (min-width: 768px) {
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
    }

    &.left-padding {
      @media (min-width: 768px) {
        padding-left: 24px;
      }
    }
  }
}

.no-padding {
  padding: 0;
}

.server-group-table {
  th {
    min-width: 0 !important;
  }
}

.server-group-table {
  tbody {
    vertical-align: initial;
  }

  .alerts-container {
    position: absolute;
    padding-bottom: 5px;
    bottom: -5px;

    .alert {
      z-index: 999;
      top: 10px;
      margin-bottom: 10px;
    }
  }
}

.server-group-content-container {
  padding-left: 24px;
  padding-right: 24px; 
  margin-bottom: 24px;
}

.documentation-container {
  background-color: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding: 24px;
}

.circle-critical {
  background-color: @hs-red;
}

.circle-warning {
  background-color: @hs-orange;
}

.circle-success {
  background-color: @hs-green;
}
